<template>
  <v-container fluid>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                AdHoc Hemp License Reports
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row>
                    <v-col sm="12" md="2">
                      <BaseDatePickerWithText
                        label="Start Date"
                        clearable
                        v-model="startDate"
                      >
                      </BaseDatePickerWithText>
                    </v-col>
                    <v-col sm="12" md="2">
                      <BaseDatePickerWithText
                        label="End Date"
                        clearable
                        v-model="endDate"
                      >
                      </BaseDatePickerWithText>
                    </v-col>

                    <v-col sm="12" md="2">
                      <v-text-field
                        v-model="searchText"
                        prepend-inner-icon="mdi-file-document-box-search"
                        label="Search"
                        hint="PreviousBusinessID, BusinessName, BusinessEmail, BusinessPhone, BusinessPhoneExt, AltEmail, AltPhone, AltPhoneExt, PhysicalAddress, PhysicalCity, PhysicalState, PhysicalZip, BillingAddress, BillingCity, BillingState, BillingZip, MailingAddress, MailingCity, MailingState, MailingZip, StatusUser, Status, LicenseType, LicenseSubTypes, BusinessType, BusinessTypeOther, "
                      ></v-text-field>
                    </v-col>
                    <v-col sm="12" md="3">
                      <v-autocomplete
                        v-model="selectedFields"
                        :items="headers"
                        item-text="text"
                        item-value="value"
                        label="Select Columns"
                        chips
                        deletable-chips
                        small-chips
                        clearable
                        multiple
                        dense
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            small
                            v-if="index < 5"
                            close
                            @click:close="selectedFields.splice(index, 1)"
                          >
                            {{ item.text }}</v-chip
                          >
                          <span v-if="index === 5" class="grey--text caption"
                            >(+{{ selectedFields.length - 5 }} others)</span
                          >
                        </template>
                        <template v-slot:prepend>
                          <reorderSelectedFields v-model="selectedFields">
                          </reorderSelectedFields>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- <v-row justify="center">
                                        <v-col sm="12" md="2">
                                            <h4 @click="toggleFilters" class="pointer primary--text">{{filterText}}
                                            </h4>
                                        </v-col>
                                    </v-row> -->
                  <v-row v-if="filters">
                    <!-- The Description and ID (item-text and item-value) 
                                        will need to be adjusted based on the properties in the array/lookup table
                                        these availableLists should be pulled from lookup tables and potentially
                                        filtered with a computed as desired (for IsActive status etc)
                                        These can also be switched to v-autocompletes if desired-->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="businessID" 
                                                :items="availableBusinessID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Business ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <BusinessID
                                            v-model="businessID"
                                            small-chips
                                            dense
                                        ></BusinessID>
                                            -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="previousBusinessID" 
                                                :items="availablePreviousBusinessID"
                                                label="Previous Business ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="licenseID" 
                                                :items="availableLicenseID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="License ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <LicenseID
                                            v-model="licenseID"
                                            small-chips
                                            dense
                                        ></LicenseID>
                                            -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="hempLicenseID" 
                                                :items="availableHempLicenseID"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Hemp License ID"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <HempLicenseID
                                            v-model="hempLicenseID"
                                            small-chips
                                            dense
                                        ></HempLicenseID>
                                            -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="businessName" 
                                                :items="availableBusinessName"
                                                label="Business Name"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="businessEmail" 
                                                :items="availableBusinessEmail"
                                                label="Business Email"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="businessPhone" 
                                                :items="availableBusinessPhone"
                                                label="Business Phone"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="businessPhoneExt" 
                                                :items="availableBusinessPhoneExt"
                                                label="Business Phone Ext"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="altEmail" 
                                                :items="availableAltEmail"
                                                label="Alt Email"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="altPhone" 
                                                :items="availableAltPhone"
                                                label="Alt Phone"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="altPhoneExt" 
                                                :items="availableAltPhoneExt"
                                                label="Alt Phone Ext"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="physicalAddress" 
                                                :items="availablePhysicalAddress"
                                                label="Physical Address"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="physicalCity" 
                                                :items="availablePhysicalCity"
                                                label="Physical City"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="physicalState" 
                                                :items="availablePhysicalState"
                                                label="Physical State"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="physicalZip" 
                                                :items="availablePhysicalZip"
                                                label="Physical Zip"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="billingAddress" 
                                                :items="availableBillingAddress"
                                                label="Billing Address"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="billingCity" 
                                                :items="availableBillingCity"
                                                label="Billing City"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="billingState" 
                                                :items="availableBillingState"
                                                label="Billing State"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="billingZip" 
                                                :items="availableBillingZip"
                                                label="Billing Zip"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="mailingAddress" 
                                                :items="availableMailingAddress"
                                                label="Mailing Address"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="mailingCity" 
                                                :items="availableMailingCity"
                                                label="Mailing City"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="mailingState" 
                                                :items="availableMailingState"
                                                label="Mailing State"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="mailingZip" 
                                                :items="availableMailingZip"
                                                label="Mailing Zip"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="businessIsActive" 
                                                :items="['true', 'false']"
                                                label="Business Is Active"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="licenseIsActive" 
                                                :items="['true', 'false']"
                                                label="License Is Active"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="statusUser" 
                                                :items="availableStatusUser"
                                                label="Status User"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="status" 
                                                :items="availableStatus"
                                                label="Status"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="licenseType" 
                                                :items="availableLicenseType"
                                                label="License Type"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="licenseSubTypes" 
                                                :items="availableLicenseSubTypes"
                                                label="License Sub Types"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="businessType" 
                                                :items="availableBusinessType"
                                                label="Business Type"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="businessTypeOther" 
                                                :items="availableBusinessTypeOther"
                                                label="Business Type Other"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- <v-col sm="12" md="3">
                                            <v-select 
                                                v-model="hempLicenseAreaCount" 
                                                :items="availableHempLicenseAreaCount"
                                                item-text="Description" 
                                                item-value="ID" 
                                                label="Hemp License Area Count"
                                                chips 
                                                deletable-chips 
                                                small-chips 
                                                clearable 
                                                multiple 
                                                dense
                                                >
                                            </v-select>
                                        </v-col> -->
                    <!-- or via a multi-id templated lookup (you will need to import and add to your components list) -->
                    <!--
                                        <HempLicenseAreaCount
                                            v-model="hempLicenseAreaCount"
                                            small-chips
                                            dense
                                        ></HempLicenseAreaCount>
                                            -->
                  </v-row>
                  <pivotOptions
                    :pivot="pivot"
                    :filteredHeaders="filteredHeaders"
                  ></pivotOptions>
                </v-card-text>
                <template>
                  <v-data-table
                    :headers="filteredHeaders"
                    :items="entries"
                    :server-items-length="total"
                    :loading="loading"
                    :sort-by.sync="pagination.sortBy"
                    :sort-desc.sync="pagination.descending"
                    :page.sync="pagination.page"
                    :items-per-page.sync="pagination.rowsPerPage"
                    :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
                    class="pt-4"
                    dense
                  >
                    <template v-slot:item.ExpirationDate="{ item }">
                      {{ item.ExpirationDate | dateformat }}
                    </template>
                    <template v-slot:item.StatusDate="{ item }">
                      {{ item.StatusDate | dateformat }}
                    </template>
                    <template v-slot:item.DateOfBirth="{ item }">
                      {{ item.DateOfBirth | dateformat }}
                    </template>
                    <template v-slot:item.BusinessInactiveDate="{ item }">
                      {{ item.BusinessInactiveDate | dateformat }}
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col sm="auto">
        <v-btn @click="getAdhoc" color="primary">
          <v-icon left>mdi-file-chart</v-icon>Generate AdHoc
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pivotOptions from '@components/reports/pivotOptions'
import reorderSelectedFields from '@components/reports/reorderSelectedFields'
import moment from 'moment'
import download from 'downloadjs'
import { get, sync, commit, call } from 'vuex-pathify'
import { authMethods, authComputed } from '@state/helpers'
import { debounce, range } from 'lodash'
var defaultSearchField = 'BusinessName'

export default {
  name: 'FlatHempLicenseReports',
  page: {
    title: 'FlatHempLicense Reports',
  },
  components: {
    pivotOptions,
    reorderSelectedFields,
  },
  data: () => ({
    valid: true,
    panel: [0, 1],
    searchText: '',
    entries: [],
    startDate: null,
    endDate: null,
    // startDate: moment()
    //     .subtract(1, 'year')
    //     .format('YYYY-MM-DD'),
    // endDate: moment().format('YYYY-MM-DD'),
    ////templateTODO: update this based on the criteria you plan to search/filter by
    // businessID: [],
    // previousBusinessID: [],
    // licenseID: [],
    // hempLicenseID: [],
    // businessName: [],
    // businessEmail: [],
    // businessPhone: [],
    // businessPhoneExt: [],
    // altEmail: [],
    // altPhone: [],
    // altPhoneExt: [],
    // physicalAddress: [],
    // physicalCity: [],
    // physicalState: [],
    // physicalZip: [],
    // billingAddress: [],
    // billingCity: [],
    // billingState: [],
    // billingZip: [],
    // mailingAddress: [],
    // mailingCity: [],
    // mailingState: [],
    // mailingZip: [],
    // businessIsActive: [],
    // licenseIsActive: [],
    // statusUser: [],
    // status: [],
    // licenseType: [],
    // licenseSubTypes: [],
    // businessType: [],
    // businessTypeOther: [],
    // hempLicenseAreaCount: [],
    pivot: {
      includePivot: false,
      pivotFirst: true,
      pivotName: '',
      rowFields: [],
      columnFields: [],
      excludeFields: [],
      dataFields: [],
      filterFields: [],
    },
    filters: true,
    filterText: '-Hide Filters-',
    total: 0,
    searchCount: 0,

    pagination: {
      page: 1,
      sortBy: defaultSearchField,
      descending: false,
      rowsPerPage: 25,
    },
    selectedFields: [],
    headers: [
      {
        text: 'Business ID',
        align: 'center',
        sortable: true,
        value: 'BusinessID',
        visible: true,
      },
      {
        text: 'Previous Business ID',
        align: 'center',
        sortable: true,
        value: 'PreviousBusinessID',
        visible: true,
      },
      {
        text: 'License ID',
        align: 'center',
        sortable: true,
        value: 'LicenseID',
        visible: true,
      },
      {
        text: 'Hemp License ID',
        align: 'center',
        sortable: true,
        value: 'HempLicenseID',
        visible: true,
      },
      {
        text: 'Business Name',
        align: 'center',
        sortable: true,
        value: 'BusinessName',
        visible: true,
      },
      {
        text: 'Business Email',
        align: 'center',
        sortable: true,
        value: 'BusinessEmail',
        visible: true,
      },
      {
        text: 'Business Phone',
        align: 'center',
        sortable: true,
        value: 'BusinessPhone',
        visible: true,
      },
      {
        text: 'Business Phone Ext',
        align: 'center',
        sortable: true,
        value: 'BusinessPhoneExt',
        visible: true,
      },
      {
        text: 'Alt Email',
        align: 'center',
        sortable: true,
        value: 'AltEmail',
        visible: true,
      },
      {
        text: 'Alt Phone',
        align: 'center',
        sortable: true,
        value: 'AltPhone',
        visible: true,
      },
      {
        text: 'Alt Phone Ext',
        align: 'center',
        sortable: true,
        value: 'AltPhoneExt',
        visible: true,
      },
      {
        text: 'Physical Address',
        align: 'center',
        sortable: true,
        value: 'PhysicalAddress',
        visible: true,
      },
      {
        text: 'Physical City',
        align: 'center',
        sortable: true,
        value: 'PhysicalCity',
        visible: true,
      },
      {
        text: 'Physical State',
        align: 'center',
        sortable: true,
        value: 'PhysicalState',
        visible: true,
      },
      {
        text: 'Physical Zip',
        align: 'center',
        sortable: true,
        value: 'PhysicalZip',
        visible: true,
      },
      {
        text: 'Billing Address',
        align: 'center',
        sortable: true,
        value: 'BillingAddress',
        visible: true,
      },
      {
        text: 'Billing City',
        align: 'center',
        sortable: true,
        value: 'BillingCity',
        visible: true,
      },
      {
        text: 'Billing State',
        align: 'center',
        sortable: true,
        value: 'BillingState',
        visible: true,
      },
      {
        text: 'Billing Zip',
        align: 'center',
        sortable: true,
        value: 'BillingZip',
        visible: true,
      },
      {
        text: 'Mailing Address',
        align: 'center',
        sortable: true,
        value: 'MailingAddress',
        visible: true,
      },
      {
        text: 'Mailing City',
        align: 'center',
        sortable: true,
        value: 'MailingCity',
        visible: true,
      },
      {
        text: 'Mailing State',
        align: 'center',
        sortable: true,
        value: 'MailingState',
        visible: true,
      },
      {
        text: 'Mailing Zip',
        align: 'center',
        sortable: true,
        value: 'MailingZip',
        visible: true,
      },
      {
        text: 'Business Is Active',
        align: 'center',
        sortable: true,
        value: 'BusinessIsActive',
        visible: true,
      },
      {
        text: 'Business Inactive Date',
        align: 'center',
        sortable: true,
        value: 'BusinessInactiveDate',
        visible: true,
      },
      {
        text: 'License Is Active',
        align: 'center',
        sortable: true,
        value: 'LicenseIsActive',
        visible: true,
      },
      {
        text: 'License Fee',
        align: 'center',
        sortable: true,
        value: 'LicenseFee',
        visible: true,
      },
      {
        text: 'Expiration Date',
        align: 'center',
        sortable: true,
        value: 'ExpirationDate',
        visible: true,
      },
      {
        text: 'Status User',
        align: 'center',
        sortable: true,
        value: 'StatusUser',
        visible: true,
      },
      {
        text: 'Status Date',
        align: 'center',
        sortable: true,
        value: 'StatusDate',
        visible: true,
      },
      {
        text: 'Status',
        align: 'center',
        sortable: true,
        value: 'Status',
        visible: true,
      },
      {
        text: 'License Type',
        align: 'center',
        sortable: true,
        value: 'LicenseType',
        visible: true,
      },
      // {
      //     text: 'License Sub Types',
      //     align: 'center',
      //     sortable: true,
      //     value: 'LicenseSubTypes',
      //     visible: true,
      // },
      {
        text: 'License Sub Type',
        align: 'center',
        sortable: true,
        value: 'LicenseSubType',
        visible: true,
      },
      {
        text: 'License Code',
        align: 'center',
        sortable: true,
        value: 'LicenseCode',
        visible: true,
      },
      {
        text: 'Revenue Code',
        align: 'center',
        sortable: true,
        value: 'RevenueCode',
        visible: true,
      },
      {
        text: 'Date Of Birth',
        align: 'center',
        sortable: true,
        value: 'DateOfBirth',
        visible: true,
      },
      {
        text: 'Business Type',
        align: 'center',
        sortable: true,
        value: 'BusinessType',
        visible: true,
      },
      {
        text: 'Business Type Other',
        align: 'center',
        sortable: true,
        value: 'BusinessTypeOther',
        visible: true,
      },
      {
        text: 'Hemp License Area Count',
        align: 'center',
        sortable: true,
        value: 'HempLicenseAreaCount',
        visible: true,
      },
    ],
  }),
  created() {
    this.selectedFields = this.headers
      .filter((h) => h.visible)
      .map((h) => h.value)
    this.bounce(this)
  },
  watch: {
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    selectedFields: {
      handler(nval, oval) {
        this.headers.forEach((item) => {
          if (nval.includes(item.value)) {
            item.visible = true
          } else {
            item.visible = false
          }
        })
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
      deep: true,
    },

    //for each search parameter we need to call bounce to search once the user has made changes
    searchText: {
      handler(nval, oval) {
        this.bounce(this)
        if (nval != oval) {
          this.queryIsModified = true
        }
      },
    },
    startDate: {
      handler() {
        this.bounce(this)
      },
    },
    endDate: {
      handler() {
        this.bounce(this)
      },
    },
    /////templateTODO: update this based on the criteria you plan to search/filter by
    //  businessID: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  previousBusinessID: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  licenseID: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  hempLicenseID: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  businessName: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  businessEmail: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  businessPhone: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  businessPhoneExt: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  altEmail: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  altPhone: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  altPhoneExt: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  physicalAddress: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  physicalCity: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  physicalState: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  physicalZip: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  billingAddress: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  billingCity: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  billingState: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  billingZip: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  mailingAddress: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  mailingCity: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  mailingState: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  mailingZip: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  businessIsActive: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  licenseIsActive: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  statusUser: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  status: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  licenseType: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  licenseSubTypes: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  businessType: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  businessTypeOther: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
    //  hempLicenseAreaCount: {
    //    handler(nval, oval) {
    //        this.bounce(this)
    //        if (nval != oval) {
    //            this.queryIsModified = true
    //        }
    //    },
    //},
  },
  computed: {
    ...authComputed,
    loading() {
      return this.searchCount > 0
    },
    //this can be used for filtering them down
    filteredHeaders: {
      get() {
        let list = this.headers.filter((h) => h.visible)
        list.sort((a, b) => {
          return (
            this.selectedFields.indexOf(a.value) -
            this.selectedFields.indexOf(b.value)
          )
        })
        return list
      },
    },
    searchParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        sortProperty: this.pagination.sortBy,
        sortDirection: this.pagination.descending ? 'desc' : 'asc',
        searchText: this.searchText,
        selectedCategories: this.selectedCategories,
        includeFields: this.selectedFields,
        queryName: this.queryName,
        queryIsModified: this.queryIsModified,
        pivotOptions: this.pivot,
        ////templateTODO: update this based on the criteria you plan to search/filter by
        // businessID: this.businessID,
        // previousBusinessID: this.previousBusinessID,
        // licenseID: this.licenseID,
        // hempLicenseID: this.hempLicenseID,
        // businessName: this.businessName,
        // businessEmail: this.businessEmail,
        // businessPhone: this.businessPhone,
        // businessPhoneExt: this.businessPhoneExt,
        // altEmail: this.altEmail,
        // altPhone: this.altPhone,
        // altPhoneExt: this.altPhoneExt,
        // physicalAddress: this.physicalAddress,
        // physicalCity: this.physicalCity,
        // physicalState: this.physicalState,
        // physicalZip: this.physicalZip,
        // billingAddress: this.billingAddress,
        // billingCity: this.billingCity,
        // billingState: this.billingState,
        // billingZip: this.billingZip,
        // mailingAddress: this.mailingAddress,
        // mailingCity: this.mailingCity,
        // mailingState: this.mailingState,
        // mailingZip: this.mailingZip,
        // businessIsActive: this.businessIsActive,
        // licenseIsActive: this.licenseIsActive,
        // statusUser: this.statusUser,
        // status: this.status,
        // licenseType: this.licenseType,
        // licenseSubTypes: this.licenseSubTypes,
        // businessType: this.businessType,
        // businessTypeOther: this.businessTypeOther,
        // hempLicenseAreaCount: this.hempLicenseAreaCount,
      }
    },
    //templateTODO: available lookup computeds should go here
  },
  methods: {
    handleError: call('errors/handleError'),
    toggleFilters() {
      this.filters = !this.filters
      if (this.filters) {
        this.filterText = '-Hide Filters-'
      } else {
        this.filterText = '-Show Filters-'
      }
    },
    searchReports() {
      if (!this.pagination.sortBy) {
        this.pagination.sortBy = defaultSearchField
        this.pagination.descending = false
      }
      if (Array.isArray(this.pagination.sortBy)) {
        if (this.pagination.sortBy.length > 0) {
          this.pagination.sortBy = this.pagination.sortBy[0]
        } else {
          this.pagination.sortBy = defaultSearchField
        }
        this.pagination.descending = false
      }
      if (!this.searchText) {
        this.searchText = ''
      }
      this.searchCount++
      const url = `AdHocFlatHempLicenseReport/Display?pageNum=${this.pagination
        .page - 1}&pageSize=${this.pagination.rowsPerPage}`
      this.$axios.post(url, this.searchParams).then(
        (res) => {
          this.pagination.rowsPerPage = res.data.Page.Size
          this.pagination.page = res.data.Page.Number + 1
          this.total = res.data.Page.TotalElements
          this.entries = res.data.Entries
          this.searchCount--
        },
        (error) => {
          this.handleError(error)
          console.log(error)
          this.searchCount--
        }
      )
    },
    getAdhoc(type) {
      let headers = { responseType: 'blob' }
      let url = 'AdHocFlatHempLicenseReport'
      this.$axios.post(url, this.searchParams, headers).then(
        (res) => {
          const content = res.headers['content-type']
          const dispo = res.headers['content-disposition']
          var filename = ''
          if (dispo && dispo.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(dispo)
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }
          download(res.data, filename, content)
        },
        (error) => {
          this.handleError(error)
          console.log(error)
        }
      )
    },

    bounce: _.debounce((self) => {
      self.searchReports()
    }, 2000),
  },
}
</script>

<style lang="scss">
@import '@design';
</style>
