<template>
  <v-container>
    <v-row>
      <v-col sm="12">
        <FlatHempLicense></FlatHempLicense>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FlatHempLicense from '@components/reports/FlatHempLicense'
export default {
  page: {
    title: 'AdhocReports',
  },
  components: {
    FlatHempLicense,
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
  watch: {},
}
</script>
<style scoped></style>
