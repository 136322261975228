<template>
  <div>
    <v-row>
      <v-col sm="12" md="3">
        <v-checkbox v-model="pivot.includePivot" label="Export With Pivot Table"></v-checkbox>
      </v-col>
      <v-col sm="12" md="3" v-if="pivot.includePivot">
        <h4 @click="togglePivotDetails" class="pointer primary--text">{{pivotText}}</h4>
      </v-col>
      <v-col sm="12" md="3" v-if="pivot.includePivot && pivotDetails">
        <v-checkbox v-model="pivot.pivotFirst" label="Pivot sheet first"></v-checkbox>
      </v-col>
      <v-col sm="12" md="3" v-if="pivot.includePivot && pivotDetails">
        <v-text-field
          v-model="pivot.pivotName"
          label="pivot name"
          hint="sheet name for pivot table"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="pivot.includePivot && pivotDetails">
      <v-col sm="12" md="3">
        <v-select
          v-model="pivot.filterFields"
          :items="availablePivotFilterFields"
          label="Report Filter Fields"
          item-text="text"
          item-value="value"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <v-col sm="12" md="3">
        <v-select
          v-model="pivot.rowFields"
          :items="availablePivotRowFields"
          label="Row Fields"
          item-text="text"
          item-value="value"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <v-col sm="12" md="3">
        <v-select
          v-model="pivot.columnFields"
          :items="availablePivotColumnFields"
          label="Column Fields"
          item-text="text"
          item-value="value"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <v-col sm="12" md="3">
        <v-select
          v-model="pivot.excludeFields"
          :items="availablePivotExcludeFields"
          label="Exclude Fields"
          item-text="text"
          item-value="value"
          chips
          deletable-chips
          small-chips
          clearable
          multiple
          dense
        ></v-select>
      </v-col>
      <v-col sm="12" md="3">
        <v-list-item>
          <v-list-item-content
            class="v-label theme--light"
            v-if="pivot.dataFields.length == 0"
          >Data Fields</v-list-item-content>
          <v-list-item-content
            class="v-label v-label--active theme--light"
            style="left: 0px; right: auto; position: absolute; transform: translateY(-18px) scale(0.75)"
            v-if="pivot.dataFields.length > 0"
          >Data Fields</v-list-item-content>
          <v-chip-group multiple column>
            <v-chip
              pill
              small
              v-for="(field, index) in pivot.dataFields"
              :key="index"
              close
              @click:close="removeDataField(field)"
            >{{field}}</v-chip>
          </v-chip-group>
          <v-list-item-icon>
            <v-icon @click="dataFieldModal=true">add</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dataFieldModal" width="350">
      <v-card class="elevation-3">
        <v-card-title class="primary">
          <h3 class="hpHeading white--text">Add data field to pivot</h3>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-row>
            <v-col sm="12" md="6">
              <v-select
                v-model="selectedDataField"
                :items="filteredHeaders"
                label="Data Field"
                item-text="text"
                item-value="value"
                dense
              ></v-select>
            </v-col>
            <v-col sm="12" md="6">
              <v-select
                v-model="selectedDataType"
                :items="availableDataTypes"
                label="Summary Type"
                item-text="text"
                item-value="value"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              color="primary"
              :disabled="!selectedDataField || !selectedDataType"
              @click="addDataField"
            >Add</v-btn>
            <v-btn @click="dataFieldModal = false" outlined color="primary">Close</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  page: {
    title: '',
  },
  model: {
    prop: 'pivot',
  },
  props: {
    pivot: {
      type: Object,
      default: {
        includePivot: false,
        pivotFirst: true,
        pivotName: '',
        rowFields: [],
        columnFields: [],
        dataFields: [],
        excludeFields: [],
        filterFields: [],
      },
    },
    filteredHeaders: Array,
  },
  components: {},
  filters: {},
  data() {
    return {
      pivotDetails: true,
      pivotText: '-Hide Details-',
      dataFieldModal: false,
      selectedDataField: '',
      selectedDataType: '',
      availableDataTypes: [
        {text: 'Sum', value: 'sum'},
        {text: 'Percent', value: 'pcnt'},
        {text: 'Count', value: 'count'},
        {text: 'Average', value: 'avg'},
        {text: 'Max', value: 'max'},
        {text: 'Min', value: 'min'},
        {text: 'Product', value: 'pdct'},
        {text: 'Count Numbers', value: 'cntNum'},
        {text: 'StdDev', value: 'stdev'},
        {text: 'StdDevp', value: 'stdevp'},
        {text: 'Var', value: 'var'},
        {text: 'Varp', value: 'varp'},
        {text: 'None', value: 'none'},
      ],
    }
  },
  created() {},
  computed: {
    availablePivotColumnFields() {
      let list = JSON.parse(JSON.stringify(this.filteredHeaders))
      this.pivot.filterFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.pivot.rowFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.pivot.excludeFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      return list
    },
    availablePivotRowFields() {
      let list = JSON.parse(JSON.stringify(this.filteredHeaders))
      this.pivot.filterFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.pivot.columnFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.pivot.excludeFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      return list
    },
    availablePivotFilterFields() {
      let list = JSON.parse(JSON.stringify(this.filteredHeaders))
      this.pivot.rowFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.pivot.columnFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.pivot.excludeFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      return list
    },
    availablePivotExcludeFields() {
      let list = JSON.parse(JSON.stringify(this.filteredHeaders))
      this.pivot.filterFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.pivot.rowFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      this.pivot.columnFields.forEach((field) => {
        let index = list.findIndex((item) => item.value == field)
        if (index > -1) {
          list.splice(index, 1)
        }
      })
      return list
    },
  },
  methods: {
    togglePivotDetails() {
      this.pivotDetails = !this.pivotDetails
      if (this.pivotDetails) {
        this.pivotText = '-Hide Details-'
      } else {
        this.pivotText = '-Show Details-'
      }
    },
    removeDataField(field) {
      let index = this.pivot.dataFields.indexOf(field)
      this.pivot.dataFields.splice(index, 1)
    },
    addDataField() {
      this.pivot.dataFields.push(
        this.selectedDataField + '|' + this.selectedDataType
      )
      this.selectedDataField = ''
      this.selectedDataType = ''
      this.dataFieldModal = false
    },
  },
  watch: {
    filteredHeaders: {
      handler(nval, oval) {
        if (this.pivot.filterFields) {
          let filterFields = this.pivot.filterFields.filter((item) => {
            return nval.find((v) => v.value == item)
          })
          this.pivot.filterFields = filterFields
        }
        if (this.pivot.rowFields) {
          let rowFields = this.pivot.rowFields.filter((item) => {
            return nval.find((v) => v.value == item)
          })
          this.pivot.rowFields = rowFields
        }
        if (this.pivot.columnFields) {
          let columnFields = this.pivot.columnFields.filter((item) => {
            return nval.find((v) => v.value == item)
          })
          this.pivot.columnFields = columnFields
        }
        if (this.pivot.dataFields) {
          let dataFields = this.pivot.dataFields.filter((item) => {
            let fieldName = item.split('|')[0]
            return nval.find((v) => v.value == fieldName)
          })
          this.pivot.dataFields = dataFields
        }
        if (this.pivot.excludeFields) {
          let excludeFields = this.pivot.excludeFields.filter((item) => {
            return nval.find((v) => v.value == item)
          })
          this.pivot.excludeFields = excludeFields
        }
      },
    },
  },
}
</script>
<style scoped>
</style>